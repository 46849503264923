import { Directive, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'img',
})
export class ImgFallbackDirective {
  @Input() imgFallback: string;

  constructor(private eRef: ElementRef, { nativeElement }: ElementRef<HTMLImageElement>) {
    const supports = 'loading' in HTMLImageElement.prototype;

    if (supports) {
      if (!nativeElement.classList.contains('no-lazy')) {
        nativeElement.setAttribute('loading', 'lazy');
      }
    }
  }

  @HostListener('error')
  loadFallbackOnError() {
    const element: HTMLImageElement = <HTMLImageElement>this.eRef.nativeElement;
    element.style.width = '100%'
    element.src =
      this.imgFallback ||
      'assets/img/placeholder.png';
  }
}
