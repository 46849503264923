import { AuthResource } from './../providers/resources/auth-resource';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseResources } from '../providers/resources/base-resources';
import { ToastService } from '../providers/services/toast-service';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { LaddaModule } from 'angular2-ladda';
import { CommonModule } from '@angular/common';
import { PipesModule } from 'src/providers/pipes/pipes.module';
import { MetaService } from 'src/providers/services/meta-services';
import { ImgFallbackDirective } from 'src/providers/directives/img-fallback.directive';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LaddaModule,
    PipesModule,
  ],
  exports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LaddaModule,
    PipesModule,
    ImgFallbackDirective
  ],
  providers: [
    ToastService,
    BaseResources,
    AuthResource,
    MetaService
  ],
  declarations: [ImgFallbackDirective],
  entryComponents: []
})
export class AppBaseModule {}
