import {Injectable} from '@angular/core';
import {
  ResourceParams,
  ResourceAction,
  ResourceRequestMethod,
  IResourceMethodPromise
} from '@ngx-resource/core';
import { BaseResources } from './base-resources';

@Injectable()
@ResourceParams({
  pathPrefix: '/auth'
})
export class AuthResource extends BaseResources {
  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/login',
    auth: false
  })
  login: IResourceMethodPromise<object, object>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/signup',
    auth: false
  })
  signup: IResourceMethodPromise<object, object>;

  @ResourceAction({
    pathPrefix: '/auth',
    method: ResourceRequestMethod.Get,
    path: '/me'
  })
  me: IResourceMethodPromise<object, object>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/user/verify/{!code}',
    auth: false
  })
  activate: IResourceMethodPromise<object, object>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/password/reset',
    auth: false
  })
  reset: IResourceMethodPromise<object, object>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/token/verify',
    auth: false
  })
  verify: IResourceMethodPromise<object, object>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/password/email',
    auth: false
  })
  recovery: IResourceMethodPromise<object, object>;
}
