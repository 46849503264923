import { ImgFallbackDirective } from 'src/providers/directives/img-fallback.directive';
import { PipesModule } from './../providers/pipes/pipes.module';
import { GlobalProvider } from './../providers/global-provider';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppBaseModule } from './app-base.module';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { LaddaModule } from 'angular2-ladda';
import { ResourceModule } from '@ngx-resource/handler-ngx-http';
import { initApp } from './app.init';
import localeMx from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { UserResource } from 'src/providers/resources/user-resource';
import { QRCodeModule } from 'angularx-qrcode';
import { Capacitor } from '@capacitor/core';

registerLocaleData(localeMx, 'es');

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md',
      backButtonIcon : 'chevron-back-outline',
      //animated: false,
      rippleEffect: false
    }),
    ResourceModule.forRoot(),
    AppRoutingModule,
    AppBaseModule,
    HttpClientModule,
    TranslateModule.forRoot({}),
    LaddaModule.forRoot({
      style: 'zoom-in',
      spinnerSize: 40,
      spinnerColor: 'white',
      spinnerLines: 12,
    }),
    PipesModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: (environment.production && !Capacitor.isNative)
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    QRCodeModule
  ],
  exports: [
    PipesModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    FirebaseAnalytics,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'es' },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [GlobalProvider],
      multi: true,
    },
    UserResource
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
