import { NotificationService } from './../providers/services/notification.service';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { GlobalProvider } from './../providers/global-provider';
import { Component, OnInit } from '@angular/core';

import { Platform, ToastController } from '@ionic/angular';
import { filter } from 'rxjs/operators';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { AppState, Plugins, StatusBarStyle } from '@capacitor/core';
import { SwUpdate } from '@angular/service-worker';
import { Capacitor } from '@capacitor/core';
const {App, Filesystem, Keyboard, StatusBar, SplashScreen, BackgroundTask} = Plugins;


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  constructor(
    private platform: Platform,
    public gp: GlobalProvider,
    protected router: Router,
    private firebaseAnalytics: FirebaseAnalytics,
    protected notificationService: NotificationService,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
  ) {
    this.appPages = this.gp.appPages;
    platform.ready().then(() => {
        this.initializeApp();
    });
  }

  initializeApp() {
  /*   this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    }); */

    if (!Capacitor.isNative) {
      return false;
    }
    StatusBar.setBackgroundColor({
      color: '#c03940'
    });
    StatusBar.setStyle({ style: StatusBarStyle.Light });


    StatusBar.show();
    this.router.events
    .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
    .subscribe(e => {
      try {
        this.firebaseAnalytics.logEvent('page_view', {
          title: window.location.pathname,
          location: window.location.href,
          page: window.location.pathname
        })
        .then((res: any) => console.log("asdasd", res))
        .catch((error: any) => console.error(error));
      } catch (e) {
        console.log(e);
      }

    });
    setTimeout(() => {
      SplashScreen.hide();
    }, 500)

  }

  ngOnInit() {
    this.notificationService.init();
    this.swUpdate.available.subscribe(async (res) => {
      const toast = await this.toastCtrl.create({
        message: 'Actualización Disponible!',
        position: 'bottom',
        buttons: [
        {
            text: 'Recargar',
            role: 'cancel',
            handler: () => {
              console.log('Cancel clicked');
            }
          }
        ]
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });


    //StatusBar.hide();
    /*StatusBar.setOverlaysWebView({
      overlay: true
    });*/
    /* const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    } */

  }
}
