import { Meta } from '@angular/platform-browser';
import {Injectable} from '@angular/core';
import * as _ from 'lodash';

/**
 * A custom Events service just like Ionic 3 Events https://ionicframework.com/docs/v3/api/util/Events/ which got removed in Ionic 5.
 *
 * @author Shashank Agrawal
 */
@Injectable()
export class MetaService {
  constructor(
    private metaTagService: Meta,
  ) {
  }

  updateTags(tags) {
    _.each(tags, (tag) => {
      if (tag['name']) {
        this.metaTagService.removeTag("name='" + tag['name'] + "'");
      }
      if (tag['property']) {
        this.metaTagService.removeTag("property='" + tag['property'] + "'");
      }

    });
    this.metaTagService.addTags(tags);
  };
}