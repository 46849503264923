import { ToDatePipe } from './toDate.pipe';
import { NgModule } from '@angular/core';
import { SafePipe } from './safe.pipe';

@NgModule({
    declarations: [
        ToDatePipe,
        SafePipe
    ],
    imports     : [],
    exports     : [
        ToDatePipe,
        SafePipe
    ]
})
export class PipesModule
{
}
