// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: 'https://api.app.extendevs.com/api/',
  resources: 'https://api.app.extendevs.com/',
  firebase: {
    apiKey: "AIzaSyCQBoXwY9bgV_hME55hg_8Vah2BYKDvLBA",
    authDomain: "app-extendevs.firebaseapp.com",
    projectId: "app-extendevs",
    storageBucket: "app-extendevs.appspot.com",
    messagingSenderId: "352424603929",
    appId: "1:352424603929:web:6ec31a9cfae17e96055588",
    measurementId: "G-5HGMVK3PSJ"
  },
  company_id : 1
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
