import { Pipe, PipeTransform } from '@angular/core';
import * as moment  from 'moment';

@Pipe({name: 'toDate'})
export class ToDatePipe implements PipeTransform
{
    /**
     * Transform
     *
     * @param {string} value
     * @param {any[]} args
     * @returns {string}
     */
    transform(value: string, args: any[] = [])
    {
        return moment(value).toDate();
    }
}
