import { environment } from './../environments/environment';
import { Injectable, Injector } from '@angular/core';
import { AuthResource } from './resources/auth-resource';

@Injectable({
  providedIn: 'root',
})
export class GlobalProvider {
  public global = {};
  list = {};
  public appPages = [
    {
      title : 'Productos y servicio',
      url: '/common/serviceProduct',
      resource: 'serviceProduct',
      query: {},
      icon: 'assets/img/ICONOS2226-26.png',
    },
    {
      title : 'Acerca de(Nosotros)',
      url: '/about',
      resource: 'a',
      query: {},
      icon: 'assets/img/ICONOS-02.png',
    },
    {
      title : 'Ofertas y promociones',
      url: '/common/offer',
      resource: 'offer',
      query: {},
      icon: 'assets/img/ICONOS-03.png',
    },
    {
      title : 'Audios',
      url: '/common/audio',
      resource: 'audio',
      query: {},
      icon: 'assets/img/ICONOS-05.png',
    },
    {
      title : 'Videos',
      url: '/common/video',
      resource: 'video',
      query: {},
      icon: 'assets/img/ICONOS-06.png',
    },
    {
      title : 'Eventos',
      url: '/common/event',
      resource: 'event',
      query: {},
      icon: 'assets/img/ICONOS-07.png',
    },
    {
      title : 'Noticias',
      url: '/common/news',
      resource: 'news',
      query: {},
      icon: 'assets/img/ICONOS2226-22.png',
    },
    {
      title : 'Sucursales',
      url: '/common/mainOffice',
      resource: 'mainOffice',
      query: {},
      icon: 'assets/img/ICONOS-09.png',
    },
    {
      title : 'Preguntas frecuentes',
      url: '/faq',
      resource: 'a',
      query: {},
      icon: 'assets/img/ICONOS2226-24.png',
    },
    {
      title : 'Aviso de privacidad',
      url: '/terms',
      resource: 'a',
      query: {},
      icon: 'assets/img/ICONOS-08.png',
    },
    {
      title : 'Código QR',
      url: '/code',
      resource: 'a',
      query: {},
      icon: 'assets/img/ICONOS-11.png',
    },
    {
      title : 'Contacto',
      url: '/contact',
      resource: 'a',
      query: {},
      icon: 'assets/img/ICONOS-10.png',
    },
  ]

  constructor(
    private injector: Injector,
  ) {
  }

  clear(): void {
    this.global = {};
  }

  set(key, value) {
    this.global[key] = value;
  }

  get(key) {
    return this.global[key];
  }

  logout() {
    this.clear();
  }

  async load() {
    const token = '';
    const authResource = this.injector.get(AuthResource);
    try {
      if (token) {
        await authResource
          .me()
          .then((data: { data }) => {
            this.set('user', data.data.user);
            this.set('token', data.data.token);
            this.set('role', data.data.role);
          })
          .catch((error) => {
            if (error.status === 401) {
              this.clear();
            }
          });
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    }
  }
}
