import { BaseResources } from './base-resources';
import {Injectable} from '@angular/core';
import {IResourceMethod, IResourceMethodObservable, ResourceAction, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';

@Injectable()
@ResourceParams({
    pathPrefix: '/user'
})
export class UserResource extends BaseResources {
    @ResourceAction({
        pathPrefix: '/auth',
        method: ResourceRequestMethod.Post,
        path: '/signup',
        auth: false
    })
    store: IResourceMethodObservable<object, object>;

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/sender'
    })
    sender: IResourceMethodObservable<object, object>;
}
