import { GlobalProvider } from 'src/providers/global-provider';
import { environment } from './../../../src/environments/environment';
import { interval } from 'rxjs';
import { ToastController, NavController, Platform } from '@ionic/angular';
import { AngularFireMessaging } from '@angular/fire/messaging';
import * as firebase from 'firebase/app';
import 'firebase/messaging';
import { Capacitor } from '@capacitor/core';

import {
  Injectable
} from '@angular/core';
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed
} from '@capacitor/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment  from 'moment';
import { mergeMapTo } from 'rxjs/operators';
import { SwPush } from '@angular/service-worker';
import { UserResource } from '../resources/user-resource';
const { Device } = Plugins;

const {
  PushNotifications,
  LocalNotifications,
  Toast
} = Plugins;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  token = '';
  timerPercentage = null;
  timerCovid$;

  constructor(
    protected userResource: UserResource,
    protected globalProvider: GlobalProvider,
    protected router: Router,
    protected toastController: ToastController,
    protected navCtrl: NavController,
    protected platform: Platform,
    protected push: SwPush,
    private afMessaging: AngularFireMessaging
  ) {
    console.log(this.platform.platforms())
    try {

      if (!Capacitor.isNative) {
        try {
          this.afMessaging.requestToken // getting tokens
          .subscribe(
            (token) => { // USER-REQUESTED-TOKEN
              this.token = token;
              this.globalProvider.global['token_sender'] = token;
              this.saveSender();
              console.log('Permission granted! Save to the server!', token);
            },
            (error) => {
              console.error(error);
            }
          );
        } catch (e) {
          console.log(e);
        }
      }
    } catch(e) {
      console.log(e);
    }
  }


  async saveSender() {
      if (!this.globalProvider.global['token_sender']) {
        return;
      }
      const info = await Device.getInfo();

      this.userResource.sender({
        uuid: info['uuid'],
        token_sender: this.globalProvider.global['token_sender'],
        company_id: environment.company_id
      }).subscribe();
  }

  init() {
    if (!Capacitor.isNative) {
      this.push.messages.subscribe(msg => console.log('push message', msg));
      this.push.notificationClicks.subscribe(click => console.log('notification click', click));

      return false;
    }
    try {
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermission().then(result => {
        if (result.granted) {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
        }
      });

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration',
        (token: PushNotificationToken) => {
          console.log('Push registration success, token: ' + token.value);
          this.token = token.value;
          this.globalProvider.global['token_sender'] = token.value;
          this.saveSender();
        }
      );

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
        (error: any) => {
          console.log('Error on registration: ' + JSON.stringify(error));
        }
      );

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotification) => {
          console.log('Push received: ' + JSON.stringify(notification));
          this.showNotification(notification);
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: PushNotificationActionPerformed) => {
          console.log('Push action performed: ' + JSON.stringify(notification));
          this.showNotification(notification);
        }
      );
    } catch (e) {
      console.log(e);
    }
  }

  showNotification(msg) {
    if (msg['notification']) {
        this.router.navigateByUrl(msg['notification']['data']['click_action'], {replaceUrl: true});
    }
    if (msg['body']) {
      this.presentToast(msg);
    }
  }

  private async presentToast(msg) {
    const toast = await this.toastController.create({
      header: msg.title,
      message: msg.body,
      position: 'bottom',
      duration: 3000,
      buttons: [
        {
          side: 'end',
          icon: 'notifications',
          handler: () => {
            this.router.navigateByUrl(msg['data']['click_action']);
          }
        }
      ]
    });
    toast.present();
  }

}
