import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  Resource,
  ResourceAction,
  ResourceRequestMethod,
  IResourceActionInner,
  IResourceResponse,
  ResourceParams,
  ResourceGlobalConfig,
  ResourceActionReturnType,
  ResourceHandler,
  IResourceMethodObservable,
  ResourceRequestBodyType,
  ResourceHelper
} from '@ngx-resource/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { ToastService } from '../services/toast-service';
import { LoadingController } from '@ionic/angular';

ResourceGlobalConfig.url = environment.api;

@Injectable({
  providedIn: 'root'
})
@ResourceParams({
  pathPrefix: '',
  returnAs: ResourceActionReturnType.Observable
})
export class BaseResources extends Resource {
  user: object;
  loadingActive;
  isLoading = true;
  loading = true;
  promises = [];
  counter = 0;
  durationLoading = 3000;
  @ResourceAction({
    method: ResourceRequestMethod.Options,
    path: '/',
    pathPrefix: '',
    auth: false
  })
  options: IResourceMethodObservable<any, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    pathPrefix: '',
    path: '/selects'
  })
  selects: IResourceMethodObservable<any, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    pathPrefix: '',
    path: '/freeSelects',
    auth: false
  })
  freeSelects: IResourceMethodObservable<any, any>;


  @ResourceAction({
    method: ResourceRequestMethod.Post,
    pathPrefix: '',
    path: '/sendEmail',
    auth: false
  })
  sendEmail: IResourceMethodObservable<any, any>;

  @ResourceAction({
    isArray: false,
    path: '/'
  })
  query: IResourceMethodObservable<any, any>;

  @ResourceAction({
    path: '/{!id}'
  })
  get: IResourceMethodObservable<any, object>;

  @ResourceAction({
    path: '/bySlug/{!slug}'
  })
  bySlug: IResourceMethodObservable<any, object>;

  @ResourceAction({
    method: ResourceRequestMethod.Post
  })
  store: IResourceMethodObservable<object, object>;

  @ResourceAction({
    method: ResourceRequestMethod.Put,
    path: '/{!id}'
  })
  update: IResourceMethodObservable<object, object>;

  @ResourceAction({
    method: ResourceRequestMethod.Delete,
    path: '/{!id}'
  })
  delete: IResourceMethodObservable<object, object>;

  @ResourceAction({
    method: ResourceRequestMethod.Put,
    path: '/{!id}/restore'
  })
  restore: IResourceMethodObservable<{ id: any }, any>;
  constructor(
    handler: ResourceHandler,
    private router: Router,
    protected translate: TranslateService,
    private _toastService: ToastService,
    @Inject(PLATFORM_ID) private platformId: Object,
    public loadingController: LoadingController,
  ) {
    super(handler);
  }

  async toLogin() {
    //window.location.href = environment.auth + '?redirect=' + window.location.href ;
  }

  async noConexion() {
    /*const toast = await this.toastController.create({
            message: 'Ha ocurrido un error, debe contar con conexion a internet',
            showCloseButton: true,
            animated: true,
            closeButtonText: 'X',
            duration: 3000,
            color: 'danger'
        });
        toast.present();*/
  }
  async awaitLoading() {
    this.durationLoading = 9999999
  }


  async completeLoading() {
    this.dismissLoading();
    this.durationLoading = 7000;
  }

  finishedRequest() {

    this.counter = this.counter - 1;
    // subscription
    this.dismissLoading();
  }

  $handleSuccessResponse(
    options: IResourceActionInner,
    resp: IResourceResponse
  ) {
    this.finishedRequest();
    return super.$handleSuccessResponse(options, resp);
  }

/*   $setBody(body) {
    body['id'] = 'asdasd';
    return body;
  }

  $setQuery(query: any) {
    query['id'] = 'asdasd';
    return query;
  } */

  async presentLoading() {
    if (this.counter === 0) {
      this.loadingActive = null;
    }
     ++this.counter;
    if (this.loading && !this.loadingActive) {
      this.loadingActive = await this.loadingController.create({
        message: '.',
        duration: this.durationLoading
      });
      await this.loadingActive.present();
      return true;
    }
    return true;
  }

  async dismissLoading() {
    if (this.loadingActive && this.isLoading) {
      return await this.loadingActive.dismiss();
    }
  }

  $handleErrorResponse(options: IResourceActionInner, resp: IResourceResponse) {
    this.finishedRequest();

    /*if (resp.status === 401 && options.actionOptions['auth'] !== false) {
      return this.toLogin();
    }*/

    if (resp.status === 403) {
      this._toastService.warning({
        message: 'No tiene permisos para realizar esta tarea'
      });
    }
    if (resp.status === 500) {
      this._toastService.error(resp.body);
    }
    super.$handleErrorResponse(options, resp);
  }

  async $getHeaders(methodOptions: any) {
    (methodOptions);
    const headers = _.assign(super.$getHeaders(), methodOptions.headers);
    //  Extending our headers with Authorization
    delete headers['Authorization'];
    headers['withCredentials'] = true;
    delete headers['if-none-match'];
    let token = '';
    if (isPlatformBrowser(this.platformId)) {
      headers['Language'] = window.localStorage['lang'] || 'en';
      token = '';
    }
      /*if (!token) {
        window.location.href = environment.auth  + '?redirect=' + window.location.href ;
        return false;
      }*/
      headers['Authorization'] = 'Bearer ' + token;
    if (methodOptions['auth'] !== false) {

    }
    //await this.presentLoading();
    return headers;
  }

  $setRequestOptionsBody(options) {
    const actionOptions = ResourceHelper.getActionOptionsOrThrow(options);
    const actionAttributes = ResourceHelper.getActionAttributesOrThrow(options);
    const requestOptions = ResourceHelper.getRequestOptionsOrThrow(options);
    let body = actionAttributes.body;
    if (!body) {
        return;
    }
    const realBodyType = ResourceHelper.getRealTypeOf(body);
    let bodyOk = realBodyType === actionOptions.requestBodyType;
    if (!bodyOk &&
        realBodyType === ResourceRequestBodyType.JSON &&
        actionOptions.requestBodyType === ResourceRequestBodyType.FORM_DATA) {
        body = ResourceHelper.createRequestOptionsFormDataBody(body, actionOptions);
        bodyOk = true;
    }
    if (!bodyOk) {
        throw new Error('Can not convert body');
    }
    let val = false;
    if (isPlatformBrowser(this.platformId)) {
      val = (!(body instanceof FormData))
    }
    if  (val){
        // Add root node if needed
        if (actionOptions.rootNode) {
            const newBody = {};
            newBody[actionOptions.rootNode] = body;
            body = newBody;
        }
        if ((actionOptions.requestBodyType === ResourceRequestBodyType.NONE ||
            (actionOptions.requestBodyType === ResourceRequestBodyType.JSON &&
                typeof body === 'object' && Object.keys(body).length === 0)) && !actionOptions.keepEmptyBody) {
            return;
        }
    }
    requestOptions.body = body;
  }
}
