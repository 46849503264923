import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import * as _ from 'lodash';

@Injectable()
export class ToastService {
  constructor(
    public toastController: ToastController // private _snackBar: MatSnackBar
  ) {}

  async error(data) {
    const toast = await this.toastController.create({
      message: data.message,
      color: 'danger',
      duration: 7000,
      buttons: [
        {
          text: 'Cerrar',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    toast.present();
    /*const toast = this._snackBar.open(data['message'], 'Cerrar', {
            duration: data['duration'] || 4000,
            panelClass: 'snack-error'
        });*/
  }

  async showErrors(data) {
    const errors = _.map(data);
    const toast = await this.toastController.create({
      message: _.join(errors, '\n'),
      color: 'danger',
      duration: 7000,
      buttons: [
        {
          text: 'Cerrar',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    toast.present();
    /*const errors = _.map(data);
        const toast = this._snackBar.open(_.join(errors, '\n'), 'Cerrar', {
            duration: 7000,
            panelClass: 'snack-error'
        });*/
  }

  async success(data) {
    const toast = await this.toastController.create({
      message: data['message'],
      color: 'success',
      duration: 7000,
      buttons: [
        {
          text: 'Cerrar',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    toast.present();
    /*const toast = this._snackBar.open(data['message'], 'Cerrar', {
            duration: 7000,
            panelClass: 'snack-success'
        });*/
  }

  async warning(data) {
    const toast = await this.toastController.create({
      message: data['message'],
      color: 'warning',
      duration: 7000,
      buttons: [
        {
          text: 'Cerrar',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    /*const toast = this._snackBar.open(data['message'], 'Cerrar', {
            duration: 7000,
            panelClass: 'snack-warning'
        });*/
  }
}
